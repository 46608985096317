<template>
  <el-main>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="180px" class="demo-ruleForm">
      <el-form-item label="积分设置："></el-form-item>
      <el-form-item label="每天释放积分比例：" prop="daily_integral">
        <el-input type="number" placeholder="每天释放积分比例" v-model="ruleForm.daily_integral">
          <template slot="append">积分</template>
        </el-input>
      </el-form-item>
      <el-form-item label="购买奖励：" prop="accumulative_integral">
        <div class="accumulativeList">
          <div class="accumulativeItem" v-for="(item, index) in ruleForm.accumulative_integral" :key="index">
            <div>单笔订单消费金额达到</div>
            <el-input type="number" size="small" v-model="item.day"></el-input>
            <div>元，送</div>
            <el-input type="number" size="small" v-model="item.integral"></el-input>
            <div>倍积分</div>
            <!-- <div class="tips" v-if="!index">此积分与每天默认得到积分数量不累计</div> -->
            <img
              v-if="ruleForm.accumulative_integral.length > 1"
              @click="ruleForm.accumulative_integral.splice(index, 1)"
              class="deleteImg"
              src="https://boweisou.oss-cn-shenzhen.aliyuncs.com/enterpriseShop/close.png"
              alt=""
            />
          </div>
          <el-button @click="addAccumulative" type="text">+ 添加奖励</el-button>
        </div>
      </el-form-item>
      <el-form-item label="分享加速规则：" prop="accumulative_integral">
        <div class="accumulativeList">
          <div class="accumulativeItem">
            <div>直接分享加速</div>
            <el-input type="number" size="small" v-model="ruleForm.direct_integral"></el-input>
            <div>间接分享加速</div>
            <el-input type="number" size="small" v-model="ruleForm.indirect_integral"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="积分规则：" prop="integral_rule">
        <RichText class="rich" @soninfo="val => (ruleForm.integral_rule = val)" :richTxt="ruleForm.integral_rule"></RichText>
      </el-form-item>
      <el-form-item label="积分释放规则："></el-form-item>
      <el-form-item label="权益卡：" label-width="200px">
        <el-input type="number" v-model="ruleForm.order_integral">
          <!-- <template slot="append">%</template> -->
        </el-input>
        <span class="tips">用于兑换权益卡或者黄金</span>
      </el-form-item>
      <el-form-item label="NTF：" label-width="200px">
        <el-input type="number" v-model="ruleForm.wifi_integral">
          <!-- <template slot="append">%</template> -->
        </el-input>
      </el-form-item>
      <el-form-item label="兑换积分：" label-width="200px">
        <el-input type="number" v-model="ruleForm.scan_to_pay_integral">
          <!-- <template slot="append">%</template> -->
        </el-input>
      </el-form-item>
      <el-form-item label="平级奖励：" label-width="200px">
        <el-input type="number" v-model="ruleForm.scan_to_order_integral">
          <template slot="append">积分</template>
        </el-input>
      </el-form-item>
      <el-form-item label="合伙人赠送积分：" label-width="200px">
        <el-input type="number" v-model="ruleForm.seeding_integral">
          <template slot="append">积分</template>
        </el-input>
      </el-form-item>
      <el-form-item label="运营中心赠送积分：" label-width="200px">
        <el-input type="number" v-model="ruleForm.share_store_integral">
          <template slot="append">积分</template>
        </el-input>
      </el-form-item>
      <el-form-item label="开启团队加速：" label-width="200px">
        <el-switch v-model="ruleForm.is_integral_discount" :active-value="1" :inactive-value="0"></el-switch>
        <!-- <span class="tips">总分计算方式包括签到积分+其他积分+摇一摇积分,但是打折扣积分只包括其他积分+摇一摇积分,向上保留到个位,即1.001积分,记作2积分</span> -->
      </el-form-item>
      <div v-if="ruleForm.is_integral_discount">
        <div class="ladderBox" v-for="(item, index) in ruleForm.integral_discount" :key="index">
          <div>
            <el-form-item label="团队总消费额度：" label-width="200px">
              <el-input type="number" v-model="item.integral">
                <template slot="append">万元</template>
              </el-input>
            </el-form-item>
            <el-form-item label="加速：" label-width="200px">
              <el-input type="number" v-model="item.discount">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </div>
          <el-button v-if="ruleForm.integral_discount.length > 1" type="text" @click="deleteCondition(index)">删除</el-button>
        </div>
        <el-button type="text" @click="addCondition">+添加条件</el-button>
      </div>
    </el-form>
    <Preservation @preservation="Preservation"></Preservation>
  </el-main>
</template>

<script>
import Preservation from '@/components/preservation.vue';
import _ from 'lodash';
import RichText from '@/components/richText';
export default {
  components: {
    Preservation,
    RichText,
  },
  data() {
    function checkDaily(rule, value, callback) {
      const reg = /^[0-9]*$/;
      if (!reg.test(value) || value <= 0) {
        callback(new Error('输入不合法,请输入大于0的数字'));
      } else if (!value) {
        callback(new Error('请填写默认得积分数'));
      } else {
        callback();
      }
    }
    function checkAccumulative(rule, value, callback) {
      const reg = /^[0-9]*$/;
      for (let i = 0; i < value.length; i++) {
        if (!reg.test(value[i].day) || !reg.test(value[i].integral) || value[i].day <= 0 || value[i].integral <= 0) {
          callback(new Error('输入不合法,请输入大于0的数字'));
          break;
        } else if (!value[i].day || !value[i].integral) {
          callback(new Error('请将数据填写完整'));
          break;
        } else {
          callback();
        }
      }
    }
    return {
      ruleForm: {
        //签到积分
        daily_integral: '',
        accumulative_integral: [{ day: '', integral: '' }],
        integral_rule: '',
        //其他积分
        order_integral: '',
        wifi_integral: '',
        scan_to_pay_integral: '',
        scan_to_order_integral: '',
        seeding_integral: '',
        share_store_integral: '',
        is_integral_discount: 0,
        integral_discount: [{ integral: '', discount: '' }],
        indirect_integral: '',
        direct_integral: '',
      },
      rules: {
        daily_integral: [{ required: true, validator: checkDaily, trigger: 'blur' }],
        accumulative_integral: [{ required: true, validator: checkAccumulative, trigger: 'blur' }],
        integral_rule: [{ required: true, message: '请填写积分规则', trigger: 'blur' }],
      },
    };
  },
  created() {
    this.getIntegralInfo();
  },
  methods: {
    addAccumulative() {
      this.ruleForm.accumulative_integral.push({ day: '', integral: '' });
    },
    Preservation() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let obj = _.cloneDeep(this.ruleForm);
          this.$axios.post(this.$api.integral.integralEdit, obj).then(res => {
            if (res.code == 0) {
              this.$message.success('设置成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getIntegralInfo() {
      this.$axios.post(this.$api.integral.integralInfo).then(res => {
        if (res.code == 0) {
          if (!res.result) return;
          for (const key in this.ruleForm) {
            if (key !== 'accumulative_integral' && key !== 'integral_discount') {
              this.ruleForm[key] = res.result[key];
            } else {
              this.ruleForm[key] = JSON.parse(res.result[key]);
            }
          }
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    addCondition() {
      this.ruleForm.integral_discount.push({ integral: '', discount: '' });
    },
    deleteCondition(index) {
      this.ruleForm.integral_discount.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;
  padding-bottom: 80px;

  .el-form {
    .el-input,
    .el-textarea {
      width: 500px;
    }

    .accumulativeList {
      .accumulativeItem {
        display: flex;
        align-items: center;

        .el-input {
          width: 150px;
          margin: 0 20px;
        }

        .deleteImg {
          width: 20px;
          height: 20px;
          margin-left: 20px;
          cursor: pointer;
          margin-left: 20px;
        }
      }
    }
  }
}

.tips {
  font-size: 12px;
  color: #999;
  margin-left: 20px;
}

.ladderBox {
  width: 800px;
  border: 1px solid #e4e4e4;
  display: flex;
  align-items: center;
  padding-top: 20px;

  .el-button {
    margin-left: 20px;
  }
}
</style>
